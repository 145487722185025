import React from 'react'
import LeaderBoardIndex from './LeaderBoardIndex'
// import NewLeaderBoardNavbar from './NewLeaderBoardNavbar/NewLeaderBoardNavbar'
import LeaderboardClock from './Clock/LeaderboardClock'
import { Col, Row } from 'react-bootstrap'
import './LeaderBoardRouting.css'
import kamaIcon from '../Images/logo.png'
import Marquee from "react-fast-marquee";
import NavigationBar from '../NavigationBar/NavigationBar'

const LeaderBoardRouting = () => {
  return (
    <div className='leader-board-routing-section'>
      {/* <NewLeaderBoardNavbar/> */}
      <NavigationBar/>
      <Row className='mt-4'>
        <Col xs={12} className='d-flex justify-content-center'>
        <div className='leader-board-timer-container'>
          <h1>GRABOW CHALLENGE ENDS IN </h1>
          <h1 className='text-center'><LeaderboardClock/></h1>
        </div>
        </Col>
      </Row>
        <Row className='mt-4'>
        <LeaderBoardIndex/>
        </Row>
        <div className='leader-board-footer-container mt-2'>
  <Marquee pauseOnHover={true} speed={60} gradient={false}>
    {/* First set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text mt-2'>Collaborate For Global Market Excellence</h1>
    </div>

    {/* Second set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text mt-2'>Navigating Markets With Precision</h1>
    </div>

    {/* Third set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text mt-2'>Access Global Markets With an Adaptive Fintech Institution</h1>
    </div>
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text mt-2'>www.kama-capital.com</h1>
    </div>
  </Marquee>
</div>
    </div>
  )
}

export default LeaderBoardRouting