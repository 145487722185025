import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import leaderVdo from '../Images/leader-board/leader-board-vdo.mp4';
import './LeaderBoardIndex.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const LeaderBoardIndex = () => {
  const [leaderData, setLeaderData] = useState([]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get('https://ifx-expo-server.xicsolutions.in/score/leaderboarddata');
      
      // Sort based on score first, then by unique_id (ascending) to get earlier registered users first
      const sortedData = response.data
        .sort((a, b) => {
          if (b.score === a.score) {
            // If scores are the same, return the earlier registered user by unique_id
            return a.unique_id - b.unique_id; // Compare by numeric value if unique_id is numeric
          }
          return b.score - a.score; // Sort by score descending
        });
      
      setLeaderData(sortedData);
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message, { autoClose: 3000 });
      } else {
        toast.error("Please wait or contact your support team.", { autoClose: 3000 });
      }
    }
  };

  useEffect(() => {
    // Fetch the leaderboard data initially
    fetchLeaderboardData();

    // Set up polling every 10 seconds
    const intervalId = setInterval(() => {
      fetchLeaderboardData();
    }, 10000); // Fetches new data every 10 seconds

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const top5 = leaderData.slice(0, 5);
  return (
    <div className="leaderboard-index-section">
      <Container fluid>
      <Row className="align-items-stretch leaderboard-index-table-vdo-row">
        <Col xs={12} md={6} className="leader-board-table-section-container">
          <TableContainer className='leader-board-table-container'>
            <Table className='laeder-board-table'>
              <TableHead>
                <TableRow>
                  <TableCell className='leader-board-table-head'>RANK</TableCell>
                  <TableCell className='leader-board-table-head'>SCORE</TableCell>
                  <TableCell className='leader-board-table-head'>IMAGE</TableCell>
                  <TableCell className='leader-board-table-head'>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {top5.map((leadersData, index) => (
                  <TableRow key={index}>
                    <TableCell className='leader-board-table-body-cell'>{index+1}</TableCell>
                    <TableCell className='leader-board-table-body-cell'>{leadersData.score}</TableCell>
                    <TableCell className='leader-board-table-body-cell'>
                      <img
                        src={`https://ifx-expo-server.xicsolutions.in/uploads/${leadersData.user_img}`}
                        alt="Raja"
                        className='leader-board-table-user-img'
                      />
                    </TableCell>
                    <TableCell className='leader-board-table-body-cell'>{leadersData.user_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center video-container">
          <video
            src={leaderVdo}
            controls
            loop
            autoPlay
            className="leader-vdo"
          />
        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default LeaderBoardIndex;
