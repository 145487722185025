import React, { useState, useEffect } from 'react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import moment from 'moment';
import './LeaderboardClock.css';

const LeaderboardClock = () => {
  // Function to calculate the time state based on current time
  const calculateTimeState = () => {
    const now = moment(); // Current time
    const startTime = moment().set({ hour: 10, minute: 0, second: 0 }); // 11:00 AM today
    const endTime = moment().set({ hour: 18, minute: 0, second: 0 }); // 6:00 PM today

    // Case 1: Before 11:00 AM, show 08:00:00
    if (now.isBefore(startTime)) {
      return { displayTime: "08:00:00", countdownTime: null }; // Fixed time before 11 AM
    }

    // Case 2: Between 11:00 AM and 6:00 PM, calculate the remaining time until 6:00 PM
    if (now.isBetween(startTime, endTime)) {
      return { displayTime: null, countdownTime: endTime.diff(now) }; // Countdown time remaining
    }

    // Case 3: After 6:00 PM, show 00:00:00
    return { displayTime: "00:00:00", countdownTime: null }; // Fixed time after 6 PM
  };

  const [timeState, setTimeState] = useState(calculateTimeState);

  useEffect(() => {
    // Update the time every second
    const timerInterval = setInterval(() => {
      setTimeState(calculateTimeState());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timerInterval);
  }, []);

  // Convert milliseconds to a Date object that FlipClockCountdown expects, only if countdownTime exists
  const endTime = timeState.countdownTime ? new Date(Date.now() + timeState.countdownTime) : null;

  return (
    <div className='leaderboard-clock'>
      {timeState.displayTime ? (
        <div className="fixed-time">{timeState.displayTime}</div> 
      ) : (
        <FlipClockCountdown
          to={endTime}
          labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase' }}
          digitBlockStyle={{ width: 60, height: 60, fontSize: 50, fontWeight: 'bold', background: 'white', color: '#6000d2' }}
          dividerStyle={{ color: 'white', height: 2 }}
          separatorStyle={{ color: 'black', size: '6px' }}
          renderMap={[false, true, true, true]} // Hours, Minutes, Seconds
          duration={0.5}
          className='flip-clock'
          showLabels={false}
        />
      )}
    </div>
  );
};

export default LeaderboardClock;
