import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./AddScoreForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const AddScoreForm = ({ open, onClose, data }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: data.user_id,
    score: "",
    admin_id: sessionStorage.getItem("admin_id") || "Admin",
    created_at: moment().format("YYYY-MM-DD HH:mm:ss")
  });
  const [rating, setRating] = useState(0); // Rating state

  const handleRatingClick = (index) => {
    const score = index;
    setRating(index); // Set the rating based on the clicked button
    setFormData({ ...formData, score });
  };

  const handleSubmit = () => {
    if (!formData.score && formData.score !== 0) {
      toast.warn("Please select a score before submitting!", { position: "top-center" });
      return;
    }
    axios
      .post(
        `https://ifx-expo-server.xicsolutions.in/score/storeUserScore`,
        formData
      )
      .then((res) => {
        toast.success("Successfully added score", { autoClose: 3000 });
        setTimeout(() => {
          navigate("/adminIndex");
        }, 3000);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { autoClose: 3000 });
        }
        console.log("Error failed to add score", err);
      });
  };

  const getButtonColor = (index) => {
    if (index <= 6) return "#FF4C4C"; // Red for 0-6
    if (index === 7 || index === 8) return "#FFDD57"; // Yellow for 7 and 8
    if (index >= 9) return "#32CD32"; // Green for 9 and 10
    return "#ccc"; // Default color
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth PaperProps={{
        style: {
          borderRadius: "20px",
          padding: "20px",
          background: "linear-gradient(145deg, #f0f0f0, #d9d9d9)",
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.1)"
        }
      }}>
      <DialogTitle style={{ textAlign: 'center', color: '#3c3c3c', fontWeight: 'bold', letterSpacing: '1px', fontSize: '24px' }}>
        Update Score
      </DialogTitle>
      <DialogContent>
      <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '18px', fontWeight: 'bold', color: '#3c3c3c' }}>
  Score: {formData.score !== "" ? formData.score : "Not selected"}
</div>


        {/* Button rating */}
        <div className="button-rating mt-5" style={{ textAlign: 'center' }}>
          {[...Array(11)].map((_, index) => (
            <Button
              key={index}
              onClick={() => handleRatingClick(index)}
              style={{
                backgroundColor: getButtonColor(index),
                color: "#fff",
                margin: "0 5px",
                padding: "10px 20px",
                borderRadius: "10px",
                fontWeight: rating === index ? "bold" : "normal",
              }}
            >
              {index}
            </Button>
          ))}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} variant="outlined" color="secondary" style={cancelButtonStyle}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={submitButtonStyle}>
          Submit
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

const cancelButtonStyle = {
  backgroundColor: "#f0f0f0",
  color: "#333",
  borderRadius: "20px",
  padding: "10px 20px",
  fontWeight: "bold",
  border: "1px solid #ccc",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
};

const submitButtonStyle = {
  backgroundColor: "#6000d2",
  color: "#fff",
  borderRadius: "20px",
  padding: "10px 20px",
  fontWeight: "bold",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
};

export default AddScoreForm;
