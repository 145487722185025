import { Link } from '@mui/material'
import React from 'react'
import './TermsAndConditions.css'

const TermsAndConditions = () => {
  return (
    <div>
      <h1 className='text-center terms-condition-title'><Link className='terms-condition-title-link'>Grabow Challenge Rules & Regulations</Link></h1>

      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Eligibility</h2></Link>
        <ul>
          <li>Participants must be at least 18 years of age.</li>
          <li>The game is open to all visitors of the Kama Capital LLC booth at the expo.</li>
        </ul>
      </div>
      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Game Mechanics</h2></Link>
        <ul>
          <li>Participants should only stand in the designated area to catch sticks that fall one by one.</li>
          <li>The number of sticks caught by the participant will be recorded.</li>
          <li>The top 5 participants who catch the most sticks will each receive a giveaway.</li>
          <li>Participants must catch at least three sticks to be eligible for a giveaway.</li>
        </ul>
      </div>
      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Tie-Breaker Rule</h2> </Link>
        <p>In the event that multiple participants catch the same number of sticks, the following tie-breaker rule will apply:</p>
        <ul>
          <li>By the end of the expo day, a death round will be conducted where the tied participants will each get one additional attempt. This will be conducted towards the official closing of booth time.</li>
          <li>The participant who catches the most sticks in the death round will only be eligible to receive the giveaway.</li>
          <li>If a tie persists, the process will be repeated until the top 5 participants are determined.</li>
        </ul>
      </div>
      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Giveaways</h2> </Link>
       
        <ul>
          <li>The top 5 participants based on the number of sticks caught will receive a giveaway.</li>
          <li>Giveaways are non-transferable and cannot be exchanged for cash or other items.</li>
          <li>Leaderboard toppers must be physically present at the booth on the same day to collect their prizes.</li>
          <li>Representatives cannot be sent to collect prizes on behalf of the leaderboard toppers.</li>
        </ul>
      </div>
      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Participation</h2></Link>
       
        <ul>
          <li>Each participant is allowed one attempt per day.</li>
          <li>Participants must register at the booth before playing the game.</li>
          <li>By participating, participants agree to share their photo & other relevant details on Kama Capital LLC's social media & other digital platforms.</li>
          <li>Participants also agree to be contacted by a company representative who will promote Kama Capital LLC's services.</li>
        </ul>
      </div>
      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Safety Guidelines</h2></Link>
        <ul>
          <li>Participants must follow the instructions provided by the game supervisor.</li>
          <li>The game area must be kept clear of obstacles.</li>
          <li>Any participant not adhering to the safety guidelines will be disqualified.</li>
        </ul>
      </div>

      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Disqualification</h2> </Link>
        <p>Participants will be disqualified for:</p>
        <ul>
          <li>Not following the game rules.</li>
          <li>Attempting to catch sticks outside the designated area.</li>
          <li>Engaging in disruptive behaviour.</li>
        </ul>
      </div>

      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Liability</h2> </Link>
        <ul>
          <li>Kama Capital LLC is not responsible for any injuries sustained during participation in the game.</li>
          <li>By participating, participants agree to release Kama Capital LLC from any liability related to the game.</li>
        </ul>
      </div>

      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Terms & Conditions</h2> </Link>
        <ul>
          <li>By participating in the 'Grabow Challenge', participants agree to these rules and regulations.</li>
          <li>Kama Capital LLC reserves the right to modify the rules or cancel the game at any time without prior notice.</li>
          <li>Any disputes will be resolved at the sole discretion of Kama Capital LLC.</li>
        </ul>
      </div>

      <div>
      <Link className='terms-condition-title-link'> <h2 className='terms-condition-sub-title'>Privacy</h2> </Link>
        <ul>
          <li>Participant data collected during registration will be used solely for the purpose of the game and follow-up communications.</li>
          <li>By participating, participants agree to the collection and use of their personal information as outlined in Kama Capital LLC'sprivacy policy.</li>
        </ul>
      </div>

    </div>
  )
}

export default TermsAndConditions
